import React, { useState } from "react";
import ChunkedPDFRenderer from "./ChunkedPDFRenderer";
import { ChevronDown, ChevronUp } from "lucide-react";

const CollapsiblePDFDisplay = ({
  chunkId,
  pdfUrl,
  pagesPerChunk,
  chunkIndex,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-4 overflow-hidden">
      <div className="px-4 py-3">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="w-full flex items-center justify-between text-sm font-medium text-blue-600 hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded"
        >
          <span>{isExpanded ? "Hide PDF" : "Show Section in PDF"}</span>
          {isExpanded ? (
            <ChevronUp className="h-5 w-5" />
          ) : (
            <ChevronDown className="h-5 w-5" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className="border-t border-gray-200">
          <ChunkedPDFRenderer
            pdfUrl={pdfUrl}
            chunkId={chunkId}
            pagesPerChunk={pagesPerChunk}
          />
        </div>
      )}
    </div>
  );
};

export default CollapsiblePDFDisplay;
