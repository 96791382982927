import React from 'react';
import { Shield, FileText, Stethoscope, Scale } from 'lucide-react';

const FeatureItem = ({ icon: Icon, text }) => (
  <div className="flex items-center space-x-3 bg-blue-100 p-3 rounded-lg mb-3 last:mb-0">
    <Icon className="w-6 h-6 text-blue-500 flex-shrink-0" />
    <span className="text-gray-700">{text}</span>
  </div>
);

const FeatureHighlights = () => (
  <section className="max-w-4xl mx-auto">
    <div className="flex flex-col md:flex-row gap-6">
      <div className="flex-1">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Automatically shows you
        </h3>
        <div>
          <FeatureItem icon={FileText} text="Proof of injury" />
          <FeatureItem icon={Stethoscope} text="Pre-existing conditions" />
          <FeatureItem icon={Scale} text="Skipped treatment options" />
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          HIPAA secure
        </h3>
        <div className="bg-blue-100 p-3 rounded-lg flex items-center flex-grow">
          <Shield className="w-12 h-12 text-blue-500 mr-4 flex-shrink-0" />
          <p className="text-gray-700">
            We prioritize patient data protection, ensuring all information is handled with the utmost security and compliance.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default FeatureHighlights;
