import React, { useState } from "react";
import { addCase } from "../services/api";
import { useNavigate } from "react-router-dom";

const CaseForm = ({ onCaseAdded }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [details, setDetails] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addCase({
        first_name: firstName,
        last_name: lastName,
        details: details,
      });
      console.log("API response:", response); // Debug log

      let newCase;
      if (Array.isArray(response)) {
        newCase = response[0]; // Take the first item if it's an array
      } else if (typeof response === "object") {
        newCase = response; // Use the object directly if it's not an array
      } else {
        throw new Error("Unexpected response format from API");
      }

      console.log("Processed new case:", newCase); // Debug log

      if (typeof onCaseAdded === "function") {
        onCaseAdded(newCase);
      } else {
        console.warn(
          "onCaseAdded is not a function. Navigating to cases page.",
        );
      }

      // Clear the form
      setFirstName("");
      setLastName("");
      setDetails("");

      // Navigate to the cases page
      navigate("/cases");
    } catch (error) {
      console.error("Error adding case:", error);
      alert("Failed to add case: " + error.message);
    }
  };

  return (
    <div className="container mx-auto px-4 max-w-2xl">
      <div className="bg-white shadow rounded-lg mt-8 mb-8 p-6">
        <h1 className="text-2xl font-bold mb-4">Add New Case</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="details"
              className="block text-sm font-medium text-gray-700"
            >
              Details (summary of the claim)
            </label>
            <textarea
              id="details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              required
              rows="4"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Case
          </button>
        </form>
      </div>
    </div>
  );
};

export default CaseForm;
