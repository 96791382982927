import React from "react";
import CollapsiblePDFDisplay from "../../components/CollapsiblePDFDisplay";

export const Alert = ({ children, className }) => (
  <div className={`p-4 rounded-md ${className}`}>{children}</div>
);

export const AlertTitle = ({ children, className }) => (
  <h3 className={`text-lg font-semibold mb-2 ${className}`}>{children}</h3>
);

export const ChunkContent = ({ children, className }) => (
  <div className={`bg-white p-4 mb-4 rounded-md shadow-sm ${className}`}>
    {children}
  </div>
);

const SharedAnalysisDisplay = ({
  content,
  pdfUrl,
  pagesPerChunk,
  alertClassName,
  titleClassName,
  renderContent,
}) => {
  return (
    <div className="space-y-6">
      {Object.entries(content)
        // Sort entries by the length of the chunk_ids array
        .sort(
          ([, a], [, b]) =>
            (b.chunk_ids?.length || 0) - (a.chunk_ids?.length || 0),
        )
        .map(([key, data]) => (
          <Alert key={key} className={alertClassName}>
            <AlertTitle className={titleClassName}>{key}</AlertTitle>
            <div className="mt-2 space-y-4">
              {/* Render summary content */}
              {renderContent(key, data)}

              {/* Render chunk-specific content */}
              {data.chunks &&
                data.chunks.map((chunk, index) => (
                  <div
                    key={index}
                    className="mt-3 bg-gray-50 p-4 rounded-lg"
                    data-conv-id={data.conv_ids?.[index]}
                  >
                    {renderContent(key, data, index)}
                    <CollapsiblePDFDisplay
                      chunkId={data.chunk_ids[index]}
                      pdfUrl={pdfUrl}
                      pagesPerChunk={pagesPerChunk}
                      chunkIndex={index}
                    />
                  </div>
                ))}
            </div>
          </Alert>
        ))}
    </div>
  );
};

export default SharedAnalysisDisplay;
