import { useState, useEffect, useCallback } from "react";
import { updateCase, fetchCaseDetails, fetchAnalysisTypes } from "../../services/api";

export const useCaseDetails = (id) => {
  const [caseData, setCaseData] = useState(null);
  const [files, setFiles] = useState([]);
  const [analyses, setAnalyses] = useState([]);
  const [analysisTypes, setAnalysisTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    const handleCaseUpdate = async (updatedData) => {
      try {
        await updateCase(id, updatedData);
        refreshCaseDetails();
      } catch (error) {
        console.error("Error updating case:", error);
        throw error;
      }
    };


  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [caseDetailsResponse, types] = await Promise.all([
        fetchCaseDetails(id),
        fetchAnalysisTypes(),
      ]);
      setCaseData(caseDetailsResponse.case);
      setFiles(caseDetailsResponse.files);
      setAnalyses(caseDetailsResponse.analyses);
      setAnalysisTypes(types);
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.response?.status === 404 ? "not_found" : "error");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refreshCaseDetails = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return {
    caseData,
    files,
    analyses,
    analysisTypes,
    loading,
    error,
    setAnalyses,
    refreshCaseDetails,
    handleCaseUpdate
  };
};
