import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchCases } from "../services/api";
import CaseForm from "./CaseForm";

const Cases = () => {
  const [cases, setCases] = useState([]);

  useEffect(() => {
    const fetchCasesData = async () => {
      try {
        const casesResponse = await fetchCases();
        setCases(casesResponse);
      } catch (error) {
        console.error("Error fetching cases:", error);
      }
    };

    fetchCasesData();
  }, []);

  const handleCaseAdded = (newCaseData) => {
    console.log("New case received:", newCaseData); // Debug log

    // Extract the case object from the array
    const newCase = Array.isArray(newCaseData) ? newCaseData[0] : newCaseData;

    if (!newCase || typeof newCase !== "object") {
      console.error("Invalid case data received");
      return;
    }

    const formattedNewCase = {
      id: newCase.id || newCase.user_id || Date.now(),
      first_name: newCase.first_name || "",
      last_name: newCase.last_name || "",
      details: newCase.details || "",
    };

    console.log("Formatted new case:", formattedNewCase); // Debug log
    setCases((prevCases) => [formattedNewCase, ...prevCases]);
  };

  return (
    <div className="container mx-auto px-4 max-w-2xl">
      <div className="bg-white shadow rounded-lg mt-8 mb-8 p-6">
        <h1 className="text-2xl font-bold mb-4">Your Cases</h1>
        <ul className="space-y-4">
          {cases.map((caseItem, index) => (
            <li
              key={caseItem.id || index}
              className="py-4 border-b border-gray-200 last:border-b-0"
            >
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="w-full sm:w-3/4 mb-2 sm:mb-0">
                  <p className="font-semibold text-lg">
                    {caseItem.first_name || caseItem.last_name
                      ? `${caseItem.first_name} ${caseItem.last_name}`.trim()
                      : "Unnamed Case"}
                  </p>
                  <p className="text-gray-600 text-sm truncate">
                    {caseItem.details
                      ? caseItem.details
                      : "No details available"}
                  </p>
                </div>
                <Link
                  className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded transition duration-300 ease-in-out w-full sm:w-auto text-center"
                  to={`/cases/${caseItem.id}`}
                >
                  View Case
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <CaseForm onCaseAdded={handleCaseAdded} />
    </div>
  );
};

export default Cases;
