import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useCaseDetails } from "./useCaseDetails";
import { CaseDetailsSection } from "./CaseDetailsSection";
import { FilesSection } from "./FilesSection";
import { AnalysisSection } from "./AnalysisSection";
import { EvidenceSection } from "./EvidenceSection";
import IntakeForm from "../../components/IntakeForm";
import NotFound from "../NotFound";

const CaseDetails = () => {
  const { id } = useParams();
  const {
    caseData,
    files,
    analyses,
    analysisTypes,
    loading,
    error,
    setAnalyses,
    refreshCaseDetails,
    handleCaseUpdate
  } = useCaseDetails(id);

    const [showIntakeForm, setShowIntakeForm] = useState(false);

  if (loading) return <div className="container mx-auto px-4 mt-4">Loading...</div>;
  if (error === "not_found") return <NotFound />;
  if (error) return <div className="container mx-auto px-4 mt-4">An error occurred. Please try again later.</div>;

  const handleIntakeFormSave = (responses) => {
    console.log('Intake form responses:', responses);
    setShowIntakeForm(false);
    refreshCaseDetails();
  };

  analyses.forEach((analysis) => {
    const file_name =
      files.find((f) => f.id === analysis.file_id)?.filename || "No File";
    analysis.file_name = file_name;
  });

  return (
    <div className="container mx-auto px-4 max-w-3xl">
      <CaseDetailsSection onUpdate={handleCaseUpdate} caseData={caseData} />
      <AnalysisSection
        caseId={id}
        setAnalyses={setAnalyses}
        analysisTypes={analysisTypes}
        files={files}
      />
      <EvidenceSection
        analyses={analyses}
        analysisTypes={analysisTypes}
        refreshAnalyses={refreshCaseDetails}
      />
    </div>
  );
};

export default CaseDetails;
