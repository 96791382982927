import React, { useState } from "react";

export const CaseDetailsSection = ({ caseData, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(caseData);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await onUpdate(editedData);
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <section className="mb-8">
      <h1 className="text-3xl font-bold mb-4 mt-4">Case Details</h1>
      <div className="bg-white shadow rounded-lg p-6 mb-6 relative">
        {error && (
          <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}
        {isEditing ? (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label htmlFor="first_name" className="block mb-1">First Name:</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={editedData.first_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label htmlFor="last_name" className="block mb-1">Last Name:</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={editedData.last_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="col-span-full">
                <label htmlFor="details" className="block mb-1">Extra information:</label>
                <textarea
                  id="details"
                  name="details"
                  value={editedData.details}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  rows="3"
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  setError(null);
                }}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <p>
                <strong>First Name:</strong> {caseData.first_name}
              </p>
              <p>
                <strong>Last Name:</strong> {caseData.last_name}
              </p>
              <p className="col-span-full">
                <strong>Extra information:</strong> {caseData.details}
              </p>
            </div>
            <button
              onClick={() => setIsEditing(true)}
              className="absolute top-4 right-4 px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors"
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </section>
  );
};
