import React from 'react';

const ThankYou = () => {
  return (
    <div className="container mx-auto px-4 max-w-2xl">
      <div className="bg-white shadow rounded-lg p-6 mb-5 mt-8">
        <h1 className="text-2xl font-bold mb-4">Thank You</h1>
        <p className="text-gray-600">
          Your intake form has been successfully submitted. We appreciate your time and will be in touch soon.
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
