import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient"; // Adjust this import based on your project structure

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const { error } = await supabase.auth.getSession();
      if (error) {
        console.error("Authentication error:", error);
        navigate("/login"); // Redirect to login page if there's an error
      } else {
        navigate("/cases"); // Redirect to the /cases page on successful authentication
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return <div>Authenticating...</div>; // You can show a loading spinner here
};

export default AuthCallback;
