import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { useAnalysis } from "./useAnalysis";
import { fetchPdfUrl } from "../../services/api";
import { pdfjs, Document, Page } from "react-pdf";
import NotFound from "../NotFound";
import DiagnosisFnDisplay from "./diagnosis_fn";
import ExplicitPreexistingCondsDisplay from "./explicit_preexisting_conds";
import PreexistingSymptomsDisplay from "./preexisting_symptoms";
import CarePlanFNDisplay from "./careplan_fn";
import "pdfjs-dist/build/pdf.worker.mjs";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BackButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="mt-4 flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200"
  >
    <ArrowLeft className="mr-2 h-5 w-5" />
    Back to Case
  </button>
);

const Analysis = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { analysis, analysisTypes, loading, error } = useAnalysis(id);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    let url;
    if (analysis && analysis.file_id) {
      const getPdfUrl = async () => {
        try {
          url = await fetchPdfUrl(analysis.file_id);
          setPdfUrl(url);
        } catch (error) {
          console.error("Error fetching PDF URL:", error);
          setPdfUrl(null);
        }
      };

      getPdfUrl();
    }

    // Cleanup function
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [analysis]);

  const handleBackClick = () => {
    // Navigate to the cases page if we don't have the analysis data yet
    navigate(analysis ? `/cases/${analysis.case_id}` : "/cases");
  };

  const renderContent = () => {
    if (loading) {
      return <div className="mt-4">Loading...</div>;
    }

    if (error === "not_found") return <NotFound />;

    if (error) {
      return (
        <div className="mt-4">An error occurred. Please try again later.</div>
      );
    }

    if (!analysis) return null;

    const currentAnalysisType = analysisTypes.find(
      (type) => type.id === analysis.type,
    );

    let contentObj;
    try {
      contentObj =
        typeof analysis.content === "string"
          ? JSON.parse(analysis.content)
          : analysis.content;
    } catch (e) {
      console.error("Error parsing analysis content:", e);
      return <div>Error: Unable to parse analysis content</div>;
    }

    const commonProps = {
      content: contentObj,
      pdfUrl: pdfUrl,
      pagesPerChunk: analysis.chunk_method?.n_pages_per_chunk,
    };

    switch (Object.keys(currentAnalysisType?.name)[0]) {
      case "diagnosis-fn":
        return <DiagnosisFnDisplay {...commonProps} />;
      case "explicit-preexisting-condition":
        return <ExplicitPreexistingCondsDisplay {...commonProps} />;
      case "preexisting-symptom":
        return <PreexistingSymptomsDisplay {...commonProps} />;
      case "careplan-fn":
        return <CarePlanFNDisplay {...commonProps} />;
      default:
        return <div>{JSON.stringify(contentObj, null, 2)}</div>;
    }
  };

  const getTitle = () => {
    return analysis?.type
      ? analysisTypes.find((type) => type.id === analysis.type)?.display_name ||
          "Unknown Analysis Type"
      : "Analysis";
  };

  return (
    <div className="container mx-auto px-4 max-w-3xl">
      <BackButton onClick={handleBackClick} />
      {!loading && !error && analysis && (
        <>
          <div className="bg-white shadow rounded-lg p-6 mb-5 mt-6">
            <p className="mb-2">
              <strong>Details:</strong>{" "}
              {Object.entries(analysis.parameters || {})
                .map(([key, value]) => `${key} is "${value}"`)
                .join(", ")}
            </p>
            <p className="mb-2">
              <strong>File:</strong> {analysis.file_name}
            </p>
            <p className="mb-2">
              <strong>Date:</strong>{" "}
              {new Date(analysis.created_at).toLocaleString()}
            </p>
          </div>
          <h1 className="text-2xl font-bold mb-4">{getTitle()}</h1>
          {analysis.chunk_method && (
            <div className="mb-4">
              Your file was analyzed by splitting it into sections and analyzing each section for evidence.
            </div>
          )}
        </>
      )}
      {renderContent()}
    </div>
  );
};

export default Analysis;
