import React, { useState, useEffect, useRef } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const PDFRenderer = ({ url, pageNumbers }) => {
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);
  const [pageWidth, setPageWidth] = useState(600);
  const containerRef = useRef(null);

  useEffect(() => {
    const updatePageWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setPageWidth(Math.min(containerWidth - 32, 600)); // 32px for padding
      }
    };

    updatePageWidth();
    window.addEventListener("resize", updatePageWidth);

    return () => window.removeEventListener("resize", updatePageWidth);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error) {
    console.error("Error loading PDF:", error);
    setError(error);
  }

  if (error) {
    return (
      <div className="text-red-500">Error loading PDF: {error.message}</div>
    );
  }

  return (
    <div ref={containerRef} className="w-full">
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        className="border border-gray-300 rounded-md p-2 max-w-full"
      >
        {pageNumbers.map((pageNumber) => (
          <div key={pageNumber} className="relative mb-4">
            <Page
              pageNumber={pageNumber}
              width={pageWidth}
              className="shadow-md"
              renderTextLayer={true}
              renderAnnotationLayer={true}
            />
            <div className="absolute bottom-2 right-2 bg-white bg-opacity-75 px-2 py-1 rounded text-sm">
              Page {pageNumber} of {numPages}
            </div>
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PDFRenderer;
